.first-level-menu {
    margin:0px;
    padding: 0px;
    list-style: none;
}

.main-li {
    position: relative;
}

.secondary-li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: purple;
    cursor: pointer;
    padding: 5px;
    min-width: 100px;
    border: 1px solid white;
    position:relative;
}

.second-level-menu-closed {
    display: none;
}

.second-level-menu-open {
    margin:10px 0px 0px 0px;
    padding: 0px;
    min-width: 250px;
    list-style: none;
    visibility: visible;
    position: absolute;
    top: 32px;
    left: 100%;
    transform: translateX(-15%);
    z-index: 10;
    background-color: red;
}

.nested-ul {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    list-style: none;
    visibility: hidden;
    position: absolute;
    top: -1px;
    left:100%;
    transform: translate(0);
    background: red;
    min-width: 200px;
}

.nested-ul > li {
    background: green;
    padding: 5px;
    border: 1px solid white
}

.secondary-li:hover > ul{
    visibility: visible;
}