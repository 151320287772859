/* #custom-scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #32343B;
}

#custom-scrollbar::-webkit-scrollbar
{
	width: 8px;
}

#custom-scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	background-color: #FED42B;

}

.MuiPaper-root::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #212328;
}

.MuiPaper-root::-webkit-scrollbar
{
	width: 8px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #FED42B;
}

.MuiTableContainer-root::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #212328;
}

.MuiTableContainer-root::-webkit-scrollbar
{
	height: 8px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #FED42B;
} */

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
  }

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #32343B;
}
  
::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #FED42B;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}
